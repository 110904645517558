import './_styles.scss';

import React from 'react';
import HamburgerButton from './hamburger';
import Logos from './logo';
import InviteModal from 'components/invite-modal';
import ShareModal from 'components/share-modal';
import MembersModal from 'components/members-modal';
import NewUserModal from 'components/new-user-modal';
import ShareSettingsModal from 'components/share-settings-modal';
import NavbarActions from './actions';
import Title from './title';
import Filestack from '../filestack';
import { useEditorStore } from 'stores/editorStore';
import { getGenericErrorMessage } from 'utilities/helper-methods';

import Api from '../../services/api';
import { toast } from 'react-toastify';

export default (props) => {
  const editorStore = useEditorStore();
  return <Navbar editorStore={editorStore} {...props}></Navbar>
}

class Navbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isInviteModalOpen: false,
      isShareModalOpen: false,
      isShareSettingsModalOpen: false,
      isSavingTitle: false,
      isSavingLogo: false, //rename?
      isFilestackOpen: false,
      previousTitle: null
    };

    this.handleDoneButtonClick = this.handleDoneButtonClick.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleInviteButtonClick = this.handleInviteButtonClick.bind(this);
    this.handleShareButtonClick = this.handleShareButtonClick.bind(this);
    this.handleShareSettingsClick = this.handleShareSettingsClick.bind(this);
    this.handleEditLogoClick = this.handleEditLogoClick.bind(this);
    this.handleHamburgerClick = this.handleHamburgerClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.setPrevTitle = this.setPrevTitle.bind(this);
    this.trySaveTitle = this.trySaveTitle.bind(this);
    this.trySaveLogo = this.trySaveLogo.bind(this);
  }

  //Title
  setPrevTitle(titleValue) {
    this.setState({ previousTitle: titleValue });
  }
  trySaveTitle() {
    //exit clause
    const { previousTitle } = this.state;
    const { title } = this.props;
    if (title === previousTitle) {
      return;
    }

    //show spinner
    this.setState({ isSavingTitle: true });

    //run save
    Api.update({ title: this.props.title }).then(() => {
      this.setState({ isSavingTitle: false, previousTitle: null });
    }).catch((e) => {
      toast.error(`Failed to update title. ${getGenericErrorMessage()}`); // TODO: localize this
    });
  }

  //Logo
  trySaveLogo(newLogo) {
    this.setState({ isSavingLogo: true });
    // save the logo and temparoraly set the logo
    const obj = { theme: { header_logo_url: newLogo.url } };
    Api.update(obj).then((res) => {
      let newAccountTheme = { ...this.props.accountTheme, logo: newLogo.temp, altLogo: newLogo.temp };
      this.props.onStatePropertyChanged('accountTheme', newAccountTheme);
      this.setState({ isSavingLogo: false });
    }).catch((err) => {
      console.log(err);
      toast.error(`Failed to update logo. ${getGenericErrorMessage()}`); // TODO: localize this
      this.setState({ isSavingLogo: false });
    });
  }

  //Navbar Actions
  handleShareButtonClick() {
    const isShareModalOpen = !this.state.isShareModalOpen;
    this.setState({
      isShareModalOpen: isShareModalOpen
    });
  }
  async handleInviteButtonClick(e) {
    let isValid = await this.props.validateOrGateUser(true);
    if (!isValid) return;

    this.setState({ isInviteModalOpen: !this.state.isInviteModalOpen });
  }
  handleHamburgerClick(e) {
    this.props.onStatePropertyChanged('isActionTrayOpen', e);
  }
  handleEditLogoClick() {
    //TODO: put this function in to the logos component (also put the fs modal there)
    const { settings } = this.props;
    const { editContent } = this.props.editorStore;

    if (editContent && settings.enableDSRLogoEdit && !this.state.isSavingLogo) {
      this.setState({ isFilestackOpen: true });
    }
  }
  async handleDoneButtonClick(e) {
    const { changesMade } = this.props.editorStore || {};

    if (changesMade) {
      await this.props.trySaveMedia();
      //Title Update
      this.trySaveTitle();
    }
    this.setPrevTitle(null);
    this.props.editorStore.onEditingComplete();
  }
  handleCancelClick(e) {
    if (this.state.previousTitle) {
      this.props.onStatePropertyChanged('title', this.state.previousTitle);
    }

    this.setPrevTitle(null);
    this.props.editorStore.onEditingComplete();
  }
  //End Navbar Actions

  handleCloseModal() {
    this.setState({
      isInviteModalOpen: false,
      isShareModalOpen: false,
      isShareSettingsModalOpen: false
    });
  }

  handleShareSettingsClick(e) {
    const isShareSettingsModalOpen = !this.state.isShareSettingsModalOpen;
    this.handleCloseModal();
    this.setState({ isShareSettingsModalOpen: isShareSettingsModalOpen });
  }

  render() {
    //todo:all this things really don't need to be declared here, trim this const list donw.
    const {
      accountTheme,
      company,
      showNewUserModal,
      userDetails,
      isLoading,
      onUsersUpdate,
      language, //pass this with the full props object instead of doing it individually
      isOwner,
      users,
      settings
    } = this.props;
    const { editContent } = this.props.editorStore;

    let { isInviteModalOpen, isShareModalOpen, isShareSettingsModalOpen } = this.state;
    let isMembersListModalOpen;
    //handle web based full screen modal request
    if (!this.props.isLoading && this.props.fullScreenModalView !== null) {
      isShareSettingsModalOpen = this.props.fullScreenModalView === 'privacy_setting';
      isMembersListModalOpen = this.props.fullScreenModalView === 'members_list';
    }

    let templates = this.props.templates ? this.props.templates : {};
    let accountSettings = this.props.accountSettings ? this.props.accountSettings : {};
    const medias = this.props.medias || [];

    //editable props
    const isEditable = isOwner && editContent;
    const isLogoEditable = isEditable && !this.state.isSavingLogo && !!settings.enableDSRLogoEdit;
    return (
      <div>
        {this.state.isFilestackOpen && (
          <Filestack
            language={this.props.language}
            subdomain={this.props.subdomain}
            onUploadSuccess={this.trySaveLogo}
            onClose={() => {
              this.setState({ isFilestackOpen: false });
            }}
          />
        )}

        <nav className="navbar">
          {/* Left Side */}
          <div className="left-container">
            {!this.props.navbar_hideHamburger && (
              <HamburgerButton onClick={this.handleHamburgerClick} {...this.props} />
            )}
            <div className="stacked">
              <Logos
                onClick={this.handleEditLogoClick}
                {...this.props}
                isEditable={isLogoEditable}
              />

              <Title
                {...this.props}
                className="top-title"
                trySaveTitle={this.trySaveTitle}
                isSaving={this.state.isSavingTitle}
                isEditable={isEditable}
                setPrevTitle={this.setPrevTitle}
              />
            </div>
          </div>

          {/* Right Side */}
          <NavbarActions
            {...this.props}
            disabled={isLoading}
            onDoneClick={this.handleDoneButtonClick}
            onInviteClick={this.handleInviteButtonClick}
            onShareClick={this.handleShareButtonClick}
            onShareSettingsClick={this.handleShareSettingsClick}
            handleCancelClick={this.handleCancelClick}
          />

          {/*move the appropriate modals in to the navbar actions control */}
          {isShareModalOpen && (
            <ShareModal
              {...this.props}
              isOpen={true}
              onRequestClose={this.handleCloseModal}
              settings={settings}
              templates={templates}
              accountSettings={accountSettings}
              medias={medias}
            />
          )}

          {isShareSettingsModalOpen &&
            <ShareSettingsModal {...this.props} onRequestClose={this.handleCloseModal} />
          }

          {isMembersListModalOpen &&
            <MembersModal {...this.props} onRequestClose={this.handleCloseModal} />
          }

          {isInviteModalOpen &&
            <InviteModal
              onRequestClose={this.handleCloseModal}
              company={company}
              userDetails={userDetails}
              settings={settings}
              language={language}
              users={users}
              onUsersUpdate={onUsersUpdate}
            />
          }

          {/* could this live in app.js? */}
          <NewUserModal
            isOpen={showNewUserModal}
            onRequestClose={this.handleCloseModal}
            hideCloseButton={true}
            useDarkOverlay={true}
            accountSettings={accountSettings}
            accountTheme={accountTheme}
          />
        </nav>

        <Title
          {...this.props}
          className="mobile-title"
          trySaveTitle={this.trySaveTitle}
          isSaving={this.state.isSavingTitle}
          isEditable={isEditable}
          setPrevTitle={this.setPrevTitle}
        />
      </div>
    );
  }
}